.footer {
    font-size: 14px;
    position: relative;
     @include media-breakpoint-up(xl) {
        font-size: 14px;
    }

     @include media-breakpoint-up(xxl) {
        font-size: 16px;
    }

    .pre-footer {
        padding: 30px 0 30px 0;
        background-color: $primary;
        color: #fff;

        a {
            color: #fff;
            text-decoration: none;
        }

        h3 {
            font-size: 18px;
            margin-top: 0;
            margin-bottom: 1rem;
        }

        .logo {
            margin-bottom: 30px;
        }

        .widget-footer {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        .widget-footer .widget:last-child {
            ul {
                margin-bottom: 0;
            }
        }

        .btn {
            padding: 6px 12px;
            min-width: 230px;

            @media (min-width: $xl) {
                font-size: 14px;
            }

            @media (min-width: $xxl) {
                font-size: 16px;
                padding: 6px 22px;
            }
        }

        .widget-footer-md {
            @media (min-width: $md) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .widget {
                    padding-right: 20px;
                }

                .logo {
                    margin-bottom: 0;
                }
            }

            @media (min-width: $xl) {
                display: block;

                .logo {
                    margin-bottom: 30px;
                }
            }
        }

    }

    .certificaciones {
        display: flex;
        padding: 5px 0;

        img {
            display: inline-block;
            width: 80px;
            margin: 0 13px 13px 0;
        }
    }

    ul {
        margin: 0 0 20px 0;
        padding: 0;
        list-style: none;

        li {
            padding: 0;

            a {
                
                display: block;
                @include media-breakpoint-up(md) {
                    padding: 3px 0;
                }
            }
        }
    }

    .i-mail {
        font-weight: 600;
        padding-left: 25px;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        &:after {
            position: absolute;
            top: 3px;
            left: 0;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f0e0";
            font-size: 14px;
        }

    }

    .i-phone {
        font-weight: 600;
        padding-left: 25px;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;

        &:after {
            position: absolute;
            top: 3px;
            left: 0;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f879";
            font-size: 14px;
        }
    }

    .i-map {
        padding-left: 25px;
        position: relative;

        &:after {
            position: absolute;
            top: 3px;
            left: 3px;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f3c5";
            font-size: 14px;
        }
    }

    .social {
        list-style: none;
        margin: 0 0 0 0;
        padding: 0;
        display: flex;

        li {
            padding: 0;

            a {
                display: block;
                font-size: 24px;
                padding: 0 15px 5px 0;
            }
        }
    }

    .rights {
        display: flex;
        flex-wrap: wrap;
        color: $primary;
        font-size: 10px;
        font-weight: bold;
        @include media-breakpoint-up(md) {
            font-size: 12px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 14px;
        }

        a {
        
            text-decoration: none;

            &:hover,
            &:focus {
                color: #000;
            }
        }

        ul {
            list-style: none;

            padding: 0;
            display: flex;

            li {
                padding: 0;
            }
        }

        .copyright {
            margin: 10px auto 10px 0;
        }

        .terms {
            margin: 10px 0 10px auto;

            li {
                a {
                    padding: 0 0 0 20px;
                }
            }
        }
    }

}