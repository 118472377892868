

.header {
    position: relative;
    top: 0;
    left: 0px;
    right: 0px;
    background: $primary;
    z-index: 1030;

    .container-- {
        max-width: 100% !important;
        padding: 0 10px;
        @media (min-width: $lg){
            padding: 0 40px;
        }
    }
    ul {
        list-style: none;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        li {
            padding: 0 10px;
            &:last-child {
                padding-right: 0;
            }
        }
    }
  

    .top-header {
        display: flex;
        width: 100%;
        background: $gris;
        width: 100%;
        padding: 0 12px;
        color: #000;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
        @media (min-width: $lg){
            font-size: 14px;
        }
        @media (min-width: $xxl){
            padding: 0 40px;
            font-size: 16px;
        }
        a {
            color: #000;
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: none;
                color: $primary;
            }
        }
        ul {
            display: flex;
            align-items: center;
            li {
                a {
                    display: flex;
                    align-items: center;
                }
            }

        }
        .fas {
            padding-right: 5px;
            font-size: 14px;
        }
        .top-left {
            margin-right: auto;
            margin-left: 0;
            padding: 0;
            li {
                padding: 0 10px 0 0;
                @media (min-width: $lg){
                    padding: 0 30px 0 0;
                }
                
            }
            

        }
   
        .top-right {
            margin-right: 0;
            margin-left: auto;
            padding: 0;
            li {
                padding: 0;
            }
            li a {
                
                padding: 0 3px 0 3px;
                border-right: 1px solid #000;
                @media (min-width: $md){
                    padding: 0 5px 0 5px;
                }
                &.active {
                    color:$primary;
                }
                .fas {
                    color:$primary;
                }
                
                
            }
            li.active a {
                color:$primary;
            }
            li:last-child a {
                border-right: 0;
                padding: 0 0 0 3px;
                @media (min-width: $md){
                    padding: 0 0 0 5px;
                }

            }
            @media (min-width: $xl){
                width: 33.333%;
                justify-content: flex-end;
            }

        }
    }




    .current-page-ancestor .nav-link,
    .current-menu-item .nav-link
    {
        color: #eee !important;
        font-weight: bold;
    }


    .navbar {
        padding: 0;
        min-height: 60px;
        background: transparent;
        position: relative;
        z-index: 999;
        
        
    }
    .dropdown-menu {
        
    }
    

    .navbar-brand {
        min-height: 60px;
        display: flex;
        align-items: center;
        margin: 10px 10px 10px 0;
    }

    .navbar-nav {
        margin: 0;
        @media (min-width: $xl){
            margin-left: auto;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }
        li {
            padding: 0;
            position: relative;
            z-index: 200;
           
        }
        li:last-child .nav-link  {
            padding-right: 0 !important;
            margin-right: 0 !important;
        }
    }

    .navbar-nav .nav-link {
        //text-transform: uppercase;
        font-weight: bold;
        color: #fff !important;
        text-transform: uppercase;
        position: relative;
        @media (min-width: $xl){
            margin-right: 10px!important;
            margin-left: 10px!important;
            padding: 0 !important;
            
        }
       
       
        &:hover, &:focus {
            text-decoration: none;
            color: #eee !important;
            
            
        }
       
        
    }




}



// navbar-toggler
.navbar-toggler {
    padding: 0;
    border-radius: 0;
    outline: 0;
    display: flex;
    flex-direction: column;
    border: 0;
    color: #fff !important;
    span {
        display: table-cell;
        vertical-align: middle;

        em {

            /* this is the menu icon */
            display: block;
            position: relative;
            height: 3px;
            width: 30px;
            background-color: transparent;
            backface-visibility: hidden;
            /* this is the menu central line */
            margin: 10px auto;
            transition: background-color .2s;
            &::after {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 3px;
                width: 30px;
                background-color: #fff;
                backface-visibility: hidden;
            }

            &::before {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 3px;
                width: 30px;
                background-color: #fff;
                backface-visibility: hidden;
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            
       

            &::after {
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            &::before {

                /* this is the menu icon top line */
                transform: rotate(-45deg);
            }

            &::after {

                /* this is the menu icon bottom line */
                transform: rotate(45deg);
            }

        }

    }

    &.collapsed span em {

        /* transform menu icon into a 'X' icon */
        background-color: #fff;

        &::before {

            /* rotate top line */
            transform: translateY(-10px);
        }

        &::after {

            /* rotate bottom line */
            transform: translateY(10px);
        }

    }

    .toggle-title {
        font-size: 10px;
        margin-top: 5px;
        font-weight: bold;;
    }

    &:hover,
    &:focus {
        box-shadow: none;
    }

}


.header.header-blue {
    background-color: $primary;
   
}

.header.header-white {
    background-color: $bg;
    .nav-link {
        color: $primary !important;
        &:hover, &:focus {
            color: $secondary !important;
        }
    }
    .navbar-contacto .nav-link {
        box-shadow: 0px 0px 8px rgba(133, 133, 133, 0.25);
        &:hover, &:focus {
            background-color: $primary !important;
            color: #fff !important;
            border: 1px solid #fff !important;
        }
    }
    // navbar-toggler
    .navbar-toggler {
   
        color: $primary !important;
        span {
            em {
                &::after {
                    background-color: $primary;
                }
                &::before {
                    background-color: $primary;
                }
            }
        }
        &.collapsed span em {
            /* transform menu icon into a 'X' icon */
            background-color: $primary;
        }
    }

}

.menu-item-has-children {
    @media (min-width: $xl){
        height: 80px;
        display: flex;
        align-items: center;
    }
}



.navbar-collapse {
    width: 100%;
}




ul.navbar-nav li.dropdown ul.dropdown-menu  {
 transition: ease-out 0.3s;
    opacity: 0;
    height: 0;
    top: 100%;
    z-index: 100;
    overflow: hidden;
    
   transform-origin: top;
  
}







ul.navbar-nav li.dropdown ul.dropdown-menu li {
//display: none;
//opacity: 0;
}



ul.navbar-nav li.dropdown:hover ul.dropdown-menu,
ul.navbar-nav li.dropdown:focus ul.dropdown-menu {
    display: block;
    top: 100%;
    left: 0;
   opacity: 1;
   height: auto;
   animation: growDown 200ms ease-in-out ;
    transform-origin: top center;
   
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
   
    80% {
        transform: scaleY(0.5)
    }
    100% {
        transform: scaleY(1)
    }
}


ul.navbar-nav li.dropdown:hover ul.dropdown-menu li,
ul.navbar-nav li.dropdown:focus ul.dropdown-menu li{
   // display: none;
//opacity: 1;
}




.dropdown-menu {
    border: 0;
    border-radius: 0;
    padding:  0 !important;
    margin: 0 !important;
    background-color: #fff;
    display: flex;
    z-index: 100;
    box-shadow: none;

   
    @media (min-width: $xl){

    }
    li {
        &:first-child {
            padding-top: 5px !important;
        }
        &:last-child {
            padding-bottom: 10px !important;
        }
    }

}
.dropdown-item {
    color: $gris-fosc;
    padding: 8px;
    @media (min-width: $xl){
        padding: 5px 10px;
    }
   
}
.dropdown-item:hover, .dropdown-item:focus {
    background-color: $primary;
    color: #fff;
}


.dropdown-toggle::after {
    display: none;
}

.custom-dropdown-toggle span{
    padding-right: 15px !important;
}

.custom-dropdown-toggle::after {
    display: inline-block;
    padding: 0;
    font-family: "Font Awesome 6 Free";
    content: '\f078';
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-transform: none;
    border: 0;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 12px;
}