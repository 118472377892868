/**
 * DO NOT CHANGE THIS FILE!
 * To override any of the settings in this section, add your styling code in the custom directory.
 * Loading first in the style.scss & classic-editor.scss
 */
/**
 * General - https://codex.wordpress.org/CSS#WordPress_Generated_Classes
 * DO NOT CHANGE THIS FILE
 * to override any of the settings in this section, add your styling code in the custom directory.
 */
.alignright {
  float: right;
  margin-left: 1rem;
}

.alignleft {
  float: left;
  margin-right: 1rem;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignwide {
  margin-left: -80px;
  margin-right: -80px;
}

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.alignfull img {
  width: 100vw;
}

.wp-caption {
  margin-bottom: 1.25rem;
  max-width: 100%;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption-text {
  margin: 0;
}

.gallery-caption {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.5;
  margin: 0;
  padding: 0.75rem;
}

.pagination {
  margin: 20px auto;
}

.sticky {
  position: relative;
  display: block;
}

.bypostauthor {
  font-size: inherit;
}

.hide {
  display: none !important;
}

/*
DO NOT CHANGE THIS FILE
to override any of the settings in this section, add your styling code in the custom directory.
 */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #eeeeee;
  clip: auto !important;
  clip-path: none;
  color: #333333;
  display: block;
  font-size: 1rem;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}


/**
 * Post Item in Archive
 */
.post .wp-post-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
}

@media (max-width: 991px) {
  .post .wp-post-image {
    max-height: 400px;
  }
}

@media (max-width: 575px) {
  .post .wp-post-image {
    max-height: 300px;
  }
}

/**
 * Comments
 */
#comments .comment-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: .9em;
}

#comments .comment,
#comments .pingback {
  position: relative;
}

#comments .comment .comment-body,
#comments .pingback .comment-body {
  display: flex;
  flex-direction: column;
  padding: 30px 0 30px 60px;
  border-bottom: 1px solid #cccccc;
}

body.rtl #comments .comment .comment-body,
body.rtl #comments .pingback .comment-body {
  padding: 30px 60px 30px 0;
}

#comments .comment .avatar,
#comments .pingback .avatar {
  position: absolute;
  left: 0;
  border-radius: 50%;
  margin-right: 10px;
}

body.rtl #comments .comment .avatar,
body.rtl #comments .pingback .avatar {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 10px;
}

#comments .comment-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: .9rem;
}

#comments .reply,
#comments .comment-metadata {
  font-size: 11px;
  line-height: 1;
}

#comments .children {
  position: relative;
  list-style: none;
  margin: 0;
  padding-left: 30px;
}

body.rtl #comments .children {
  padding-left: 0;
  padding-right: 30px;
}

#comments .children li:last-child {
  padding-bottom: 0;
}

#comments ol.comment-list .children:before {
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
  line-height: 100%;
  content: '\21AA';
  position: absolute;
  top: 45px;
  left: 0;
  width: auto;
}

body.rtl #comments ol.comment-list .children:before {
  content: '\21A9';
  left: auto;
  right: 0;
}

@media (min-width: 768px) {

  #comments .comment-author,
  #comments .comment-metadata {
    line-height: 1;
  }
}

@media (max-width: 767px) {
  #comments .comment .comment-body {
    padding: 30px 0;
  }

  #comments .children {
    padding-left: 20px;
  }

  #comments .comment .avatar {
    position: inherit;
    float: left;
  }

  body.rtl #comments .comment .avatar {
    float: right;
  }
}