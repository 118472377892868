
// forms



.contact-form, .wpforms-form {
  
    .form-control {
        background-color: transparent;
        color: $secondary;
       
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-radius: 8px !important;
        padding: 5px 20px;
         box-shadow: 0px 0px 14px rgba(149, 149, 149, 0.25) !important;
         max-width: 100% !important;
        &::placeholder {
            color: $secondary;
        }
    }
    textarea {
        height: 120px;
    }
    .wpcf7-spinner {
        display: none;
    }
}

.form-group {
   margin-bottom: 1rem;
 
   label {
   
      display: none;
     
   }
   .wpcf7-form-control-wrap {
       width: 100%;
   }
}


// ctf7
div.wpcf7 .ajax-loader {
    display: none !important;
}
.wpcf7-list-item-label {
    padding-left: 23px;
}

.wpcf7-list-item {
    margin: 0 !important;
}

.option-left-normal {
    overflow: hidden !important;

    input[type=radio],
    input[type=checkbox] {
        display: none !important;
    }

    input[type=radio]+label,
    input[type=checkbox]+label {
        padding: 0 0 0 22px !important;
        margin: 0 auto !important;
        display: inline-block !important;
        cursor: pointer !important;
        position: relative !important;
    }

    input[type=checkbox]+label:before, input[type=checkbox]+.wpcf7-list-item-label:before {
        content: '' !important;
        border-radius: 0 !important;
    }

    input[type=checkbox]:checked+label:before,  input[type=checkbox]:checked+.wpcf7-list-item-label:before {
      
        content: "✓" !important;
        background-color: $primary !important;
        border: 1px solid $primary !important;
        color: $white !important;
        line-height: 1 !important;

    }

    input[type=radio]+label:before {
        content: '' !important;
        border-radius: 50% !important;
        cursor: pointer !important;
    }



    label {
        display: inline-block !important;
        //float: left;
        cursor: pointer !important;
        position: relative !important;
        font-size: 14px !important;


        &:before {
            float: left !important;
            content: '' !important;
            display: flex !important;
            align-self: center !important;
            justify-content: center !important;
            width: 16px !important;
            height: 16px !important;
            margin: -8px 0 0 0 !important;
            background-color: $white !important;
            border: 1px solid $gray-500 !important;
            position: absolute !important;
            top: 50% !important;
            left: 0 !important;

        }
    }
    .wpcf7-list-item-label {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;
        font-size: 14px;


        &:before {
            float: left;
            content: '';
            display: flex;
            align-self: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin: -8px 0 0 0;
            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 50%;
            left: 0;
            //font-size: 10px;
        }
    }
    .wpforms-field-label-inline {
        display: inline-block;
        //float: left;
        cursor: pointer;
        position: relative;
        font-size: 14px;


        &:before {
            float: left;
            content: '';
            display: flex;
            align-self: center;
            justify-content: center;
            width: 16px;
            height: 16px;
            margin: -8px 0 0 0;
            background-color: $white;
            border: 1px solid $gray-500;
            position: absolute;
            top: 50%;
            left: 0;
            //font-size: 10px;
        }
    }
}

