// Core
.elementor-section {
    --e-global-color-primary: #0E66AA;
    --e-global-color-secondary: #5380BC;
    --e-global-color-text: #000000;
    --e-global-color-accent: #0E66AA;
    --e-global-color-889b0c6: #849DCD;
    --e-global-color-a99ea58: #AFBCDF;
    --e-global-typography-primary-font-family: "Open Sans Condensed";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Open Sans Condensed";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Open Sans";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Open Sans";
    --e-global-typography-accent-font-weight: 500;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


::-moz-selection {
    background: $primary;
    color: white;
}

::selection {
    background: $primary;
    color: white;
}

:-webkit-any-link {
    color: $primary;
}

:-moz-any-link {
    color: $primary;
}

:any-link {
    color: $primary;
}



html,
body {
    margin: 0 !important;
    padding: 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    background-color: $bg;

}


html {
    font-size: 16px;
}

body {
    background-color: $bg;
    color: #303030;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    overflow-x: hidden;

}


.text-justify {
    text-align: justify;
}

img {
    max-width: 100%;
    height: auto;
}

b,
strong {
    font-weight: bold;
}

section {
    position: relative;
    width: 100%;
    overflow-x: visible;
}

ul li {
    padding-bottom: 0;
}

ul.no-margin li,
.no-margin ul li {
    padding-bottom: 0;
}


a,
.elementor a {
    text-decoration: underline;
    color: $primary;
    transition: 0.2s;
}

.elementor-heading-title {
    margin: 1rem 0;
}




.title,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.elementor-heading-title {
    line-height: 1.3;
    font-weight: bold;
    font-family: $font-title, serif;
    
}

.elementor-section {
    --e-global-typography-primary-font-family: "Open Sans";
    --e-global-typography-primary-font-weight: 700;
}



h1,
.h1 {
    font-size: clamp(28px, 4vw, 50px);
    margin: 4rem 0 2rem 0;


}

h2,
.h2 {
    font-size: clamp(24px, 4vw, 38px);
    margin: 4rem 0 2rem 0;

}


h3,
.h3 {
    font-size: clamp(20px, 4vw, 30px);
    margin: 4rem 0 2rem 0;

}

h4,
.h4 {
    font-size: clamp(18px, 4vw, 24px);
    margin: 4rem 0 2rem 0;


}

h5,
.h5 {
    font-size: clamp(18px, 4vw, 20px);
}






.title-black {
    color: #000;
}

.title-primary {
    color: $primary;
}

.title-secondary {
    color: $secondary;
}


.title-white {
    color: #fff;
}





.sticky-row {
    position: relative;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.sticky-sidebar {
    position: relative;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.fix-top {
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    z-index: 400;
}

.encima {
    z-index: 900;
}


// Elementor
@mixin make-max-widths-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {

    @each $breakpoint,
    $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

.content .elementor-section .elementor-container.elementor-column-gap-default,
.content .elementor-section .elementor-container.elementor-column-gap-wider,
.elementor-location-archive .elementor-container.elementor-column-gap-default,
.elementor-location-single .elementor-container.elementor-column-gap-wider,
.container {
    @include make-max-widths-container-width();
   // max-width: 100% !important;
    //padding: 0 2%;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 0;
}

.elementor-column-gap-default>.elementor-column>.elementor-element-populated {
    padding: 0 10px;
}



.elementor img {}


.elementor-button {
    text-decoration: none !important;
}




// Magnificpopup
.mfp-iframe-holder {
    padding-left: 0;
    padding-right: 0;
}

.mfp-iframe-holder .mfp-content {
    max-width: 1280px;
}

.mfp-iframe-scaler iframe video {
    width: 100%;
}




.z-100 {
    z-index: 100;
}

.z-200 {
    z-index: 200;
}
.z-300 {
    z-index: 300;
}
.z-400 {
    z-index: 400;
}
.z-500 {
    z-index: 500;
}



.center-content {
    max-width: 960px;
    margin: 0 auto;
}


.bg-gris {
    background-color: $gris;
}

.bg-gris-clar {
    background-color: $gris;
}
//rgb(215 217 218 / 20%);

.bg-cover {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    background-blend-mode: darken;
    transform: translate(-50%, -50%);
}

.bg-image {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
}

.video-post {
    overflow: hidden;
    position: relative;
    max-width: 960px;
    margin: 0 auto 1rem auto;
    background-color: #000;
    &:hover, &:focus {
        .play-i img {
            transform: scale(1.2);
           
        }
    }
}

.play-i {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    img {
        transition: all 0.2s ease-in-out;
    }
}

.aparecer {
    opacity: 0;
}



/* ratios option */
.r-1x1 {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.r-1x125 {
    width: 100%;
    height: 0;
    padding-bottom: 125%;
}

.r-1x138 {
    width: 100%;
    height: 0;
    padding-bottom: 138%;
}

.r-1x15 {
    width: 100%;
    height: 0;
    padding-bottom: 150%;
}


.r-1x2 {
    width: 100%;
    height: 0;
    padding-bottom: 200%;
}




.r-2x1 {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
}
.r-4x3 {
    width: 100%;
    height: 0;
    padding-bottom: 75%;
}
.r-16x9 {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.r-21x9 {
    width: 100%;
    height: 0;
    padding-bottom: 42.857143%;
}



@media (max-width:600px) {
    .mobile_1x1 {
        padding-bottom: 100% !important ;
    }
    .banner-description {

        display: none;
    }
}


/* position option */

.p-top {
    justify-content: flex-start !important;
}

.p-center {
    justify-content: center !important;
}

.p-bottom {
    justify-content: flex-end !important;
}




/* banners content */
.banner-title-section {
    padding: 50px 0;
    text-align: center;
    margin: 0 auto;
    max-width: 960px;
}


.banner-image {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}

.banner-video {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}

.banner-title {
    font-weight: 400 !important;
    text-align: center;
}



.text-shadow {
    text-shadow: 
    1px 1px 1px #3E3E3E;

}

.banner-description {
    font-weight: 300 !important;
    text-align: center;
    padding-bottom: 20px;
}

.banner-description p {
    line-height: 1.2 !important;
}


.button_white {
    padding: 15px 30px;
    width: 100%;
    max-width: 350px;
    border-radius: 0;
    text-decoration: none;
    display: inline-block;
    background-color: #fff !important;
    color: $primary !important;
    border: 2px solid $primary !important;
    font-weight: bold !important;
    margin: 0 auto;
    line-height: 1.2;
    text-align: center;
    font-size: 16px !important;
    transition: all 0.3s;
    text-decoration: none !important;
    text-transform: uppercase;
    text-shadow: none !important;
}

.button_white:hover, .button_white:focus {
    background-color: #fff !important;
    color: $primary !important;
    border: 2px solid $primary !important;
    box-shadow: 3px 3px 0 $primary;
}

.button_grey {
    padding: 8px 30px;
    min-width: 200px;
    border-radius: 0;
    text-decoration: none;
    display: inline-block;
    background-color: #3E3E3E !important;
    color: #fff !important;
    border:1px solid #3E3E3E !important;
    font-weight: bold !important;
    margin: 0 auto;
    line-height: 1.2;
    text-align: center;
    font-size: 16px !important;
    transition: all 0.3s;
    text-decoration: none !important;
}

.button_grey:hover, .button_grey:focus {
    background-color: #fff !important;
    color: #3E3E3E !important;
    border: 1px solid #3E3E3E !important;
}


.button_primamry, .button_primary {
    padding: 8px 30px;
    min-width: 200px;
    border-radius: 0;
    text-decoration: none;
    display: inline-block;
    background-color: $primary !important;
    color: #fff !important;
    border:1px solid $primary !important;
    font-weight: bold !important;
    margin: 0 auto;
    line-height: 1.2;
    text-align: center;
    font-size: 16px !important;
    transition: all 0.3s;
    text-decoration: none !important;
    &.current {
        background-color: #fff !important;
        color: $primary !important;
        border: 1px solid $primary !important;
    }
}

.button_primamry:hover, .button_primamry:focus,
.button_primary:hover, .button_primary:focus
.button_primary.current {
    background-color: #fff !important;
    color: $primary !important;
    border: 1px solid $primary !important;
}


.lista_destacada  ul {
    list-style: none;
    padding: 0;
    font-size: 18px;
    li {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 1.5em;

        &:before {
            content: '\e90e';
            font-family: 'eicons';
            display: inline-block;
            margin-left: -1.5em;
            width: 1.5em;
            color: $primary;
        }

    }
}

.img-150 {
    img {
        width: 150px;
    }
}

.img-100 {
    img {
        width: 100px;
    }
}

.sombra {
    text-shadow: -3px -3px 12px rgba(0, 0, 0, 0.3), 3px 3px 12px rgba(0, 0, 0, 0.3);
}

.mb-20 {
    margin-bottom: 20px;
}