/* owl-carousel */
$owl-color-base:            $primary !default;
$owl-color-white:           $primary !default;
$owl-color-gray:            #D6D6D6 !default;

//nav
$owl-nav-color:             $owl-color-white !default;
$owl-nav-color-hover:       $owl-color-white !default;
$owl-nav-font-size:         50px !default;
$owl-nav-rounded:           3px !default;
$owl-nav-margin:            5px !default;
$owl-nav-padding:           4px 7px !default;
$owl-nav-background:        $owl-color-gray !default;
$owl-nav-background-hover:  $owl-color-base !default;
$owl-nav-disabled-opacity:  0.8 !default;

//dots
$owl-dot-width:             10px !default;
$owl-dot-height:            10px !default;
$owl-dot-rounded:           30px !default;
$owl-dot-margin:            5px !default;
$owl-dot-background:        $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;



.owl-carousel {
    .owl-nav {
        margin-top: 0;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        [class*='owl-'] {
            color: $owl-color-white !important;
            display: inline-block;
            cursor: pointer;
            border-radius: 0;
            width: 50px;
            height: 50px;
            
            text-align: center;
            position: absolute;
            top: 50%;
            margin: -25px 0 0 0;
            background: transparent;
          
            &:hover, &:focus {
                color: $owl-color-white !important;
                text-decoration: none;
                outline: none;
            }
            
        }
        .owl-prev {
            left: 0;
            
        }
        .owl-next {
            right: 0;
            
        }
        .disabled {
            opacity: $owl-nav-disabled-opacity;
            cursor: default !important;

        }
    }

    // Styling dots
    .owl-nav.disabled + .owl-dots {
        margin-top: 10px;
    }

    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        position: absolute;
		bottom: 0;
        left: 0;
        right: 0;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;

            span {
                width: $owl-dot-width;
                height: $owl-dot-height;
                margin: $owl-dot-margin;
                background: $white;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 50%;
                border: 1px solid $primary;
            }

            &.active,
            &:hover, &:focus {
                outline: none;
                span {
                    background: $primary;
                    border: 1px solid $primary;
                }
            }
        }
    }
}
