$primary: #0079c1;
$primary_light: #F4F5F8;
$secondary: #65afd4;
$secondary_light: #AFBCDF;

$bg: #fff;


$gris: #f8f9fa;
$gris-fosc: #747678;
$white: #fff;
$black: #000;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;


$font-title:  'Arimo';
$font-family-base: 'Arimo';


$facebook: #3B5998;
$twitter: #61c5ec;
$whatsapp: #4acb59;
$github: #000000;
$linkedin: #0671ae;
$youtube: #f70100;
$instagram: #cf2f79;

