.cta_slider_home {
    padding: 0;
    background-color: $primary;
    color: #fff;
    overflow: hidden;
    position: relative;
    min-height: calc(100vh - 140px);
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(md) {
        padding:  0;
    }

    .cta_text {
        //max-width: 500px;
        position: relative;
        z-index: 500;
        min-height: 420px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-shadow: -3px -3px 12px rgba(0, 0, 0, 0.3), 3px 3px 12px rgba(0, 0, 0, 0.3);
    }

    .logo {
        margin-bottom: 2rem;
    }

    .title {
        color: #fff;
        margin: 0 0 1rem 0;
        text-transform: uppercase;
        line-height: 1.1;
    }

    .subtitle {
        text-transform: uppercase;
        margin: 0 0 2rem 0;
        line-height: 1.1;
       
    }

    .cta_buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        a {
           
        }
    }
    a {
        color: #fff;
    }

    
}

.down {
    position: absolute;
    bottom: 20px;
    left: 50%;
    color: #fff;
    font-size: 30px;
    z-index: 300;
    transform: translate(-50%, 0);
    line-height: 1;
    i {
        animation: scale 2.5s linear infinite;
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(1);
    }
}

// Slide portada
.slide-principal {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
    .slide {
        width: 100%;
        height: calc(100vh - 100px);
        position: relative;
    }

    .owl-carousel .owl-nav [class*=owl-] {
        color: #fff !important;
    }
    .owl-nav {
        display: none;
        @media (min-width: $md){
            display: block;
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 15px;
        right: 20px;
        width: auto;
        left: auto;
        .owl-dot span {
            width: 17px;
            height: 17px;
            background: transparent;
            border: 1px solid #fff;
        }
        .owl-dot.active span, .owl-dot:hover span, .owl-dot:focus span {
            background: #fff;
            border: 1px solid #fff;
        }

    }
}




.page_title {
    
    
    position: relative;
    background-color: $primary;
    color: #fff;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    @include media-breakpoint-up(md) {
      min-height: 260px;
    }

    @include media-breakpoint-up(xxl) {
    
        min-height: 300px;
    }
    .title {
        text-transform: uppercase;
        margin-top: 0;
 
    }
    .subtitle {
        text-transform: uppercase;
        margin: 0;
        font-weight: 500;
    }
    .cta_text {
       position: absolute;
        top: 50%;
        left: 50%;
        
        transform: translate(-50%, -50%);
        width: 100%;
        text-shadow: -3px -3px 12px rgba(0, 0, 0, 0.3), 3px 3px 12px rgba(0, 0, 0, 0.3);
    }
    .parallax-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bg-cover {
        opacity: 0.7;
        background-color: $primary;
        //transition: all 0.2s ease-in-out;
    }
  
}




/* banner_image */
.banner-image {
    border: 3px solid #fff;
}



.cta_image {
    height: 0;
    padding-bottom: 50%;
    position: relative;
    background-color: $primary;
    color: #fff;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(md) {
        padding-bottom: 40%;
    }

    @include media-breakpoint-up(xxl) {
        padding-bottom: 0;
        height: auto;
        min-height: 600px;
    }
    .title {
        text-transform: uppercase;
        margin-top: 0;
 
    }
    .subtitle {
        text-transform: uppercase;
        margin: 0;
        font-weight: 500;
    }
    .cta_text {
       position: absolute;
        top: 50%;
        left: 50%;
        
        transform: translate(-50%, -50%);
        width: 100%;
        text-shadow: -3px -3px 12px rgba(0, 0, 0, 0.3), 3px 3px 12px rgba(0, 0, 0, 0.3);
    }
    .parallax-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bg-cover {
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
    &.is-active {
        .bg-cover {
            opacity: 1;
        }
    }
}





.procedimientos {
    padding: 50px 0 50px 0;

    @include media-breakpoint-up(md) {
        padding: 100px 0 100px 0;
    }

    .title {
        color: $primary;

        font-weight: 600;
        margin: 0 0 20px 0;

    }

    .subtitle {

        font-weight: 700;
        margin: 0 0 60px 0;
        color: $primary;

        span {
            color: $secondary;
        }


    }

    .modulo-text {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
        height: 0;

        &.current {
            visibility: visible;
            opacity: 1;
            height: auto;
        }

    }

    .m-title {
        color: $primary;
        font-weight: 700;
        margin: 0 0 30px 0;
    }
}

.banner-image {
    transition: 0.5s all;
}



.projecte {
    text-align: center;
    width: 100%;
    position: relative;
    margin-bottom: 0;
    display: block;
    text-decoration: none;
    overflow: hidden;
    background-color: #000;
    border: 1px solid #fff;
    &:hover, &:focus {
        .projecte-cover {
            opacity: 1;
        }
        .banner-image {
            transform: scale(1.02);
        }
    }
    figure {
        margin: 0;
        
        opacity: .8;
    }

    
    .banner-image {
        transition: 0.5s all;
    }
    a {
        text-decoration: none;
    }
    .projecte-cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.4);
        opacity: 0;
        transition: 0.3s all;
    }

    .projecte-info {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
    }
    .projecte-title {
        margin: auto;
        padding: 20px 20px;
        text-transform: uppercase;
        @include media-breakpoint-up(lg) {
            padding: 20px 20px;
            //margin: 20px 0;
        }
    }

}
