.post-excerpt {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding-bottom: 5px;
    border-bottom: 1px solid $primary;

    a {
        text-decoration: none;
    }
    font-size: 14px;
    .projecte-content {
        color: #000;
    }
    figure {
        margin: 0;
        overflow: hidden;
    }
    &:hover, &:focus {
        text-decoration: none;
        .banner-image {
            transform: scale(1.05);
        }
    }
}

.wp-caption-text {
    margin: 0;
    font-size: 12px;
    padding: 5px 0;
}

.single-post {
    img {
        padding: 20px 0;
    }
}



#browsing {
    clear: both;
}

.previouspostslink a, .nextpostslink a {
    color: $primary;
    &:hover, &:focus {
        color: $secondary;
        text-decoration: none;;
    }
}

.wp-pagenavi {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: center; 
    position: relative;
    padding:0;
    margin: 2rem 0 3rem 0;
    color: $body-color;
    clear: both;
    line-height: 1;
    a {
        color: $body-color;
        padding: 0.5em;
        text-decoration: none;
        //border-right: 1px solid  #d3ced2;
        &:hover,
        &:focus {
            text-decoration: none;
            color: $primary;
        }
    }
    .extend {
    }


    .current {
        padding: 0.5em;
        background:  rgba($secondary, 0.2);
        color: $primary;
    }
}

.navegacio {
    a {

    }
}


.slide-entradas {
    padding-bottom: 50px;
    .projecte {
        margin-bottom: 3rem;
    }
    .owl-stage {
        display: flex;
        flex-direction: row;
        align-items: stretch;

    }

    .owl-item {
        display: flex;

    }
    .owl-dots {
        right: 100px;
        left: 100px;
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
    .owl-nav {
        width: 100px;
        height: 50px;
        position: absolute;
        bottom: 0;
        right:0;
        background: #FFFFFF;
        //box-shadow: 0px 0px 8px rgba(164, 176, 203, 0.5);
        border-radius: 0;

      

        .button.owl-next,
        .owl-next {
            border-left: 1px solid $primary !important;
        }
    }
}